html, body, #root {
  min-height: 100vh;
}

.video-grid{
  display: grid;
  height: 100vh;
  width: 100%;
}

.video-grid-local-stream {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: rgba(100, 100, 100, 0.15);
}

.video-grid-remote-stream {
  position: relative;
  box-sizing: border-box;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.15);
  border-style: solid;
}

.video-grid-name-tag {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}